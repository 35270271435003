<script>
import GenericButton from "@/Components/GenericButton.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import TextInput from "@/Components/TextInput.vue";
import SearchBar from "@/Components/searchBar.vue";
import CandidateLayout from "@/Layouts/CandidateLayout.vue";
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import PostInput from "./Center/PostInput.vue";
import PostCard from "./Partials/PostCard.vue";
import RegionCard from "./Right/RegionCard.vue";

export default defineComponent({
    components: {
        LoadingSpinner,
        GenericButton,
        TextInput,
        //SearchBar,
        PostInput,
        PostCard,
        RegionCard,
    },
    computed: {
        hasPostCompanyPermission() {
            if (
                this?.$page?.props?.auth?.organization === null &&
                this?.$page?.props?.auth?.permissions?.length === 0
            ) {
                console.log("org and permissions are null");
                return true;
            } else {
                if (
                    this?.$page?.props?.auth?.permissions?.includes(
                        "post_company"
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    data() {
        return {
            loading: false,
            page: 1,
            lastPost: false,
        };
    },
    setup(props, { emit }) {
        //    onMounted(() => {
        //      if(galleryImages.value.length > 5) currentIndex.value = galleryImages.value.length - 4;
        //      currentIndex.value = 2;
        //    })
        // Create a local reactive state that mirrors the posts prop
        const localPosts = ref(props.posts ? [...props.posts] : []);

        // Update localPosts whenever props.posts changes
        watch(
            () => props.posts,
            (newPosts) => {
                localPosts.value = newPosts ? [...newPosts] : [];
            }
        );

        const currentIndex = ref(0);
        const galleryImages = ref([
            //{ media1: { url: 'https://ralfvanveen.com/wp-content/uploads/2021/06/Placeholder-_-Glossary.svg' } },
            //{ media1: { url: 'https://img.freepik.com/free-vector/illustration-gallery-icon_53876-27002.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1718236800&semt=sph' } },
            //{ media1: { url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwpkud_BqL_JvbLUuYFUxNq2m44WfsbFpGGw&s' } },
            // { media1: { url: '/storage/media/dE98TpKjD8gD9rs7tDZ8uJKiOV5jOFp6A5apNMPg.png' } },
            // { media1: { url: '/storage/media/uGEkNKQlHhOVfPmdRtj1IlONYk1GH4VxJ1B6SuIW.gif' } },
            // { media1: { url: '/storage/media/TXPTPw1ZPm7BIavZC1qSAI0nt5GYzoQSqsQljywg.jpg' } },
            // { media1: { url: '/storage/media/dE98TpKjD8gD9rs7tDZ8uJKiOV5jOFp6A5apNMPg.png' } },
            // { media1: { url: '/storage/media/uGEkNKQlHhOVfPmdRtj1IlONYk1GH4VxJ1B6SuIW.gif' } },
        ]);

        const updateMainImage = (index) => {
            currentIndex.value = index;
            const mainImage = document.querySelector("#mainImage");
            mainImage.src = galleryImages.value[index].media1.url;
        };

        const prevImage = () => {
            currentIndex.value =
                currentIndex.value > 0
                    ? currentIndex.value - 1
                    : galleryImages.value.length - 1;
            updateMainImage(currentIndex.value);
        };
        const nextImage = () => {
            currentIndex.value =
                currentIndex.value < galleryImages.value.length - 1
                    ? currentIndex.value + 1
                    : 0;
            updateMainImage(currentIndex.value);
        };

        const handleData = (value) => {
            // Update local state instead of props
            localPosts.value = value;
            // Emit event to update parent state
            // emit('update:posts', value);
        };
        return {
            localPosts,
            handleData,
            galleryImages,
            currentIndex,
            nextImage,
            prevImage,
            updateMainImage,
        };
    },
    methods: {
        addGtmScript() {
            const script1 = document.createElement("script");
            script1.async = true;
            script1.src =
                "https://www.googletagmanager.com/gtag/js?id=AW-16689675207";
            script1.id = "gtm-script1";
            document.head.appendChild(script1);

            const script2 = document.createElement("script");
            script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-16689675207');
            `;
            script2.id = "gtm-script2";
            document.head.appendChild(script2);

            const script3 = document.createElement("script");
            script3.id = "gtm-script3";
            script3.innerHTML = `
                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof(url) != 'undefined') {
                        window.location = url;
                        }
                    };
                    gtag('event', 'conversion', {
                        'send_to': 'AW-16689675207/ClHwCOneps8ZEMf3oJY-',
                        'value': 1.0,
                        'currency': 'CHF',
                        'transaction_id': '',
                        'event_callback': callback
                    });
                    return false;
                }
             `;
            document.head.appendChild(script3);
        },
        removeGtmScript() {
            const script1 = document.getElementById("gtm-script1");
            const script2 = document.getElementById("gtm-script2");
            const script3 = document.getElementById("gtm-script3");
            if (script1) document.head.removeChild(script1);
            if (script2) document.head.removeChild(script2);
            if (script3) document.head.removeChild(script3);
        },
        async loadMorePosts() {
            if (this.loading) return;

            this.loading = true;

            try {
                const response = await axios.get(route("feed.fetch-posts"), {
                    params: {
                        page: this.page + 1,
                    },
                });

                const newPost = response.data;
                //check if empty hide load more button
                if (newPost.length === 0) {
                    this.lastPost = true;
                }
                if (newPost) {
                    this.localPosts.push(...newPost);
                    this.page += 1;
                }
            } finally {
                this.loading = false;
            }
        },
        handleScroll() {
            if (this.lastPost) return;
            if (route().current() === "feed") {
                const feedScrollElement =
                    document.getElementById("feed-scroll");

                if (feedScrollElement) {
                    const bottomOfElement =
                        feedScrollElement.scrollTop +
                            feedScrollElement.clientHeight >=
                        feedScrollElement.scrollHeight - 200;

                    if (bottomOfElement) {
                        this.loadMorePosts();
                    }
                }
            }
        },
    },
    mounted() {
        // Add the Google Tag Manager script dynamically
        this.addGtmScript();
        const feedScrollElement = document.getElementById("feed-scroll");

        if (feedScrollElement) {
            console.log("feed-scroll element found");
            feedScrollElement.addEventListener("scroll", this.handleScroll);
        }
    },
    destroyed() {
        const feedScrollElement = document.getElementById("feed-scroll");

        if (feedScrollElement) {
            feedScrollElement.removeEventListener("scroll", this.handleScroll);
        }
    },
    beforeUnmount() {
        // Vue 3 syntax, use beforeDestroy for Vue 2
        // Optional: Clean up the GTM script if needed
        this.removeGtmScript();
    },

    props: {
        posts: {
            type: Array,
            default: () => [],
        },
        redirectToSubmenu: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        organization: {
            type: Object,
            default: {},
        },
        region: {
            type: Object,
            default: {},
        },
        organizationId: Number,
        currentorganization: {
            type: Array,
        },
        superUser: {
            type: Boolean,
        },
        users: {
            type: Array,
        },
        seoData: {
            type: Object,
        },
    },
    layout: CandidateLayout,
});
</script>

<template>
    <AppHead
        :title="seoData?.title"
        :description="seoData?.description"
        :keywords="seoData?.keywords"
    />
    <div
        class="flex flex-col w-full gap-6 md:flex-row h-full md:h-[calc(100vh_-_116px)] overflow-hidden relative top-4"
    >
        <div
            id="feed-scroll"
            class="relative flex flex-col w-full h-full p-px mx-auto overflow-auto md:w-2/3 md:max-w-[680px]"
        >
            <div>
                <h2
                    class="mb-1 text-lg leading-[125%] md:leading-normal md:text-xl font-bold text-primary ml-3 md:ml-0"
                    v-if="!$page.props.auth?.user"
                >
                    {{ $t("Welcome, to the home of hospitality.") }}
                </h2>
            </div>
            <div v-if="hasPostCompanyPermission" class="mb-6">
                <PostInput
                    v-if="$page.props.auth?.user"
                    :currentorganization="currentorganization"
                    @update:data="handleData"
                />
                <div
                    class="flex flex-col items-center justify-center min-h-80"
                    v-if="!localPosts.length"
                >
                    <h2 class="mb-2 text-2xl font-bold">
                        {{ $t("No posts to show") }}
                    </h2>
                    <p class="mb-12 text-sm text-disabled">
                        {{ $t("No posts to show") }}
                    </p>
                    <GenericButton
                        class="flex items-center justify-center border-[#919EAB33] gap-2 px-4 py-2 text-sm font-bold text-primary transition-all bg-transparent border rounded-lg"
                    >
                        {{ $t("Find friends") }}
                    </GenericButton>
                </div>
            </div>
            <!-- <div v-if="region" class="flex w-full overflow-auto shrink-0">
                <RegionCard
                    v-if="region"
                    :region="region"
                    :isRegionPage="false"
                />
            </div> -->
            <div class="flex flex-col gap-6 pb-4 shrink-0">
                <PostCard
                    v-for="(post, index) in localPosts"
                    class="post"
                    :key="post.id"
                    :superUser="superUser"
                    :post="post"
                    @update:data="handleData"
                    :comingFrom="'Feed'"
                    :users="users"
                    v-bind:style="{ animationDelay: index * 0.1 + 's' }"
                />
            </div>
            <LoadingSpinner v-if="loading" class="absolute -bottom-20 !h-10" />
        </div>
        <div
            v-if="region"
            class="hidden w-1/3 max-w-[344px] overflow-auto md:flex"
        >
            <RegionCard v-if="region" :region="region" :isRegionPage="false" />
        </div>
    </div>
</template>

<style scoped>
.post {
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease-out forwards;
    opacity: 0;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
